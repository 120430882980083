.navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: hsla(50, 86%, 80%, 1);
  backdrop-filter: blur(10px);
  border: 1px solid var(--jet);
  border-radius: 12px 12px 0 0;
  box-shadow: var(--shadow-2);
  z-index: 5;
}

.navbar-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.navbar-link {
  color: rgb(12, 12, 12);
  font-size: var(--fs-4);
  padding: 20px 7px;
  transition: color var(--transition-1);
}

.navbar-link:hover,
.navbar-link:focus { color: var(--light-gray-70); }

.navbar-link.active { color: rgb(247, 85, 85); }


@media (min-width: 580px) {

  .navbar { border-radius: 20px 20px 0 0; }

  .navbar-list { gap: 20px; }

  .navbar-link { --fs-8: 14px; }

}

@media (min-width: 768px) {

  .navbar-link { --fs-8: 15px; }

}

@media (min-width: 1024px) {


  .navbar {
    position: absolute;
    bottom: auto;
    top: 0;
    left: auto;
    right: 0;
    width: max-content;
    border-radius: 0 20px;
    padding: 0 20px;
    box-shadow: none;
  }

  .navbar-list {
    gap: 30px;
    padding: 0 20px;
  }

  .navbar-link { font-weight: var(--fw-500); }

}

