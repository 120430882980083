.projects {

  padding-top: 30px;

}

.post:hover {

  cursor: pointer;

}

.soon {
  text-align: center;
  font-size: 12px;
  font-weight: var(--fw-600);
  width: 100%;
}

.select-item button {
  background: var(--eerie-black-2);
  color: var(--light-gray);
  font-size: var(--fs-6);
  font-weight: var(--fw-300);
  text-transform: capitalize;
  width: 100%;
  padding: 8px 10px;
  border-radius: 8px;
}

.select-item button:hover { --eerie-black-2: hsla(50, 86%, 80%, 1); }

.project-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  margin-bottom: 10px;
}

.project-item { display: none; }

.project-item.active {
  display: block;
  animation: scaleUp 0.25s ease forwards;
}

@keyframes scaleUp {
  0% { transform: scale(0.5); }
  100% { transform: scale(1); }
}

.project-item > a { width: 100%; }

.project-img {
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 15px;
}

.project-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1;
  transition: var(--transition-1);
}

.project-item > a:hover .project-img::before { background: hsla(0, 0%, 0%, 0.5); }

.project-item-icon-box {
  --scale: 0.8;

  background: var(--bg-gradient-onyx);
  color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(var(--scale));
  font-size: 20px;
  padding: 18px;
  border-radius: 12px;
  opacity: 0;
  z-index: 1;
  transition: var(--transition-1);
}

.project-item > a:hover .project-item-icon-box {
  --scale: 1;
  opacity: 1;
}

.project-item-icon-box ion-icon { --ionicon-stroke-width: 50px; }

.project-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition-1);
}

.project-item > a:hover img { transform: scale(1.1); }

.project-title,
.project-category { margin-left: 10px; }

.project-title {
  color: var(--white-2);
  font-size: var(--fs-5);
  font-weight: var(--fw-400);
  text-transform: capitalize;
  line-height: 1.3;
}

.project-category {
  color: var(--light-gray-70);
  font-size: var(--fs-6);
  font-weight: var(--fw-300);
}

@media (min-width: 450px) {

  .project-img { height: auto; }

}

@media (min-width: 580px) {

  .project-img { border-radius: 16px; }

}

@media (min-width: 768px) {

  .article-title { padding-bottom: 20px; }

  .filter-select-box { display: none; }

  .filter-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    padding-left: 5px;
    margin-bottom: 30px;
  }

  .filter-item button {
    color: var(--light-gray);
    font-size: var(--fs-5);
    transition: var(--transition-1);
  }

  .filter-item button:hover { color: var(--light-gray-70); }

  .filter-item button.active { color: var(--orange-yellow-crayola); }

  .project-list { grid-template-columns: 1fr 1fr; }

}

@media (min-width: 1024px) {

  .project-list { grid-template-columns: repeat(3, 1fr); }

}