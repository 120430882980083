
.about .article-title { margin-bottom: 15px; }

.about-text {
  color: var(--light-gray);
  font-size: var(--fs-4);
  font-weight: var(--fw-300);
  line-height: 1.6;
}

.about-text p { margin-bottom: 15px; }



/**
 * #service 
 */

.service { margin-bottom: 35px; }

.service-title { margin-bottom: 20px; }

.service-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.service-item {
  position: relative;
  background: var(--border-gradient-onyx);
  padding: 20px;
  border-radius: 14px;
  box-shadow: var(--shadow-2);
  z-index: 1;
}

.service-item::before {
  content: "";
  position: absolute;
  inset: 1px;
  background: var(--bg-gradient-jet);
  border-radius: inherit;
  z-index: -1;
}

.service-icon-box { margin-bottom: 10px; }

.service-icon-box img { margin: auto; }

.service-content-box { text-align: center; }

.service-item-title { margin-bottom: 7px; }

.service-item-text {
  align-items: center;
  justify-content: center;
  color: var(--light-gray);
  font-size: var(--fs-6);
  font-weight: var(--fw-3);
  line-height: 1.6;
}


  
/* service */

.service-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 18px;
    padding: 30px;
}

.service-icon-box {
    margin-bottom: 0;
    margin-top: 5px;
}

.service-content-box { text-align: left; }


.resume {

    padding-top: 20px;
    
}

.timeline { margin-bottom: 30px; }

.timeline .title-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 25px;
}

.timeline-list {
  font-size: var(--fs-6);
  margin-left: 45px;
}

.timeline-item { position: relative; }

.timeline-item:not(:last-child) { margin-bottom: 20px; }

.timeline-item-title {
  font-size: var(--fs-6);
  line-height: 1.3;
  margin-bottom: 7px;
}

.skills-item-title {
  font-size: var(--fs-6);
  line-height: 1.3;
  margin-bottom: 7px;
  color: aliceblue;
}

.timeline-list span {
  color: var(--vegas-gold);
  font-weight: var(--fw-400);
  line-height: 1.6;
}

.timeline-item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: -25px;
  left: -30px;
  width: 1px;
  height: calc(100% + 50px);
  background: var(--jet);
}

.timeline-item::after {
  content: "";
  position: absolute;
  top: 5px;
  left: -33px;
  height: 6px;
  width: 6px;
  background: var(--text-gradient-yellow);
  border-radius: 50%;
  box-shadow: 0 0 0 4px var(--jet);
}

.timeline-text {
  color: var(--light-gray);
  font-weight: var(--fw-300);
  line-height: 1.6;
}


@media (min-width: 580px) {

    .timeline-list { margin-left: 65px; }

    .timeline-item:not(:last-child)::before { left: -40px; }
  
    .timeline-item::after {
      height: 8px;
      width: 8px;
      left: -43px;
    }

}


@media (min-width: 1024px) {


    .service-list {
      grid-template-columns: 1fr 1fr;
      gap: 20px 25px;
    }
  
  }

  @media (min-width: 1250px) {

    .timeline-text { max-width: 700px; }

  }

