/*-----------------------------------*\
  #MAIN
\*-----------------------------------*/

main {
  margin: 15px 12px;
  margin-bottom: 75px;
  min-width: 259px;
}

@media (min-width: 580px) {

   main {
    margin-top: 60px;
    margin-bottom: 100px;
  }

}

@media (min-width: 768px) {


}

@media (min-width: 1024px) {

   main { margin-bottom: 60px; }


  .main-content {
    position: relative;
    width: max-content;
    margin: auto;
  }

}

@media (min-width: 1280px) {

   main {
    max-width: 1200px;
    margin-inline: auto;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 25px;
  }

  .main-content {
    min-width: 73%;
    width: 73%;
    margin: 0;
  }

}